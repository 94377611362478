pre {
	tab-size: 2;
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 1.4em;
	overflow: auto;
	code[class] {
		white-space: pre;
	}
}

td {
	pre {
		margin: 0;
		padding-left: 0px;
		overflow: visible;
		line-height: $root-line-height;
		code {}
	}
}

%code-font {
	font-family: $code-font-family;
	font-size: $code-font-size;
}

%code-in-rounded-box {
	border-radius: 4px;
	padding: 2px 4px;
	color: #bf616a;
	background-color: #f9f2f4;
}

%code-style-codersblock {
	padding: 2px 4px;
	border: 1px dotted #adb5db;
	background-color: $gray-1;
}

%code-style-npf {
	padding: .1em .1em;
	color: #ab5979;
	border-radius: 3px;
}

a {
	code {
		color: $link-color;
	}
}

code {
	@extend %code-font;
}

p code {
	background-color: $gray-2;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 1px;
}

// highlight.js
.hljs {
	font-family: $code-font-family;
	font-size: $code-fence-font-size;
	line-height: $root-line-height;
	white-space: pre;
	border: 1px solid $gray-3;
	border-radius: 4px;
}
